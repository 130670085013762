.UserHelper{
    .UserHelperNavbarInvisibleHandler{
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    //background-color: bisque;
}
    .UserHelperMenu{
        z-index: 999;
        width: 270px;
        height: 420px;
        background-color: #252528;
        border-radius: 25px;
        position:fixed;
        
        bottom: 10%;
        right: 10%;
        border: black solid 0.3em;
        overflow: hidden;
    }
    .UserHelperBtn{
        z-index: 1000;
        position: relative;
        margin-left: 2px;
        // position: fixed;
        // bottom: 1.5%;
        // right: 1.5%;
        font-weight: 700;
        font-size: 2em;
        width: 1.3em;
        height: 1.3em;
        border-radius: 0.5em;
        background-color: #252528;
        color: white;
        border: black solid 0.1em;
        cursor: pointer;
        user-select: none;
        overflow: hidden;

        .slider{
           position: relative;
           height: 100%;

            .el{
                width: 100%;
                height: 100%;
                background-color: #252528;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
    
                &:nth-of-type(2){
                    position: relative;
                    background-color: #252528;
                    color: white;
                }
            }
        }
        
    }
    

.uhw-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1020;
    margin-top: 0.1em;
    font-weight: 700;
    font-size: 2em;
    width: 1.3em;
    height: 1.3em;
    border-radius: 0.5em;
    background-color: #252528;
    color: white;
    border: black solid 0.15em;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    
}
.uhw-btn-exit{
    margin-top: 0.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1021;
    position: fixed;
    top: 0%;
    right: 3%;
    cursor: pointer;
    font-weight: 700;
    font-size: 2em;
    width: 1.3em;
    height: 1.3em;
    border-radius: 0.5em;
    background-color: #252528;
    color: white;
    border: black solid 0.15em;
    user-select: none;
    overflow: hidden;
    
    
}
.uhw-btn-invisible{
    margin-top: 0.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1020;
    // position: fixed;
    // top: 0%;
    // right: 3%;
    opacity: 0;


    font-weight: 700;
    font-size: 2em;
    width: 1.3em;
    height: 1.3em;
    border-radius: 0.5em;
    background-color: #252528;
    color: white;
    border: black solid 0.15em;
    user-select: none;
    overflow: hidden;
    
    
}

.UserHelperNavbar{
    position: relative;
    z-index: 110;
    height: 100%;
    padding: 20px 20px 30px 20px;
    box-sizing: border-box;
    overflow: hidden;
    //overflow-y: auto;
    // .scrolik{
        
    // }
    .body{
        overflow-x: hidden;
        // display: flex;
        // gap: 10px;
        width: 85%;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
        .uhn-title{
            color: #cbcbcb;
            font-weight: 600;
            font-size: 120%;
            padding-bottom: 20px;
            
            
        }
        .uhn-btn{
            white-space: pre-wrap;
            border-radius: 0.25em;
            margin-bottom: 0.15em;
            padding-top: 0.2em;
            padding-bottom: 0.2em;
            color: #cbcbcb;
            border: black solid 0.15em;
            text-decoration: none;
            font-size: 100%;
            user-select: none;
            cursor: pointer;
        }
    }
}

}

.UserHelperWindow{
    z-index: 1010;
    position: fixed;
    transform: translate(-50%,-50%);//--- powoduje szpare miedzy borderem a divem idk jak naprawic
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%; 
    box-sizing: border-box;
    background-color: #252528;
    border: solid black 0.25em;
    display: flex;
    justify-content: center;
    //border-radius: 4em;
    overflow: hidden;
    padding-bottom: 0%;
    
    .body-uhw{
        display: flex;
        width: 90%;
        color: #cbcbcb;
        justify-content: center;
        height: 100%;
        white-space: pre-wrap;
        overflow-y: auto;
        padding-left: 5%;
        padding-right: 5%;
       
        //background-color: #252528;

        .user-helper-handler{ 
            max-width: 1000px;
            padding-bottom: 50px;
            .tekst{
                padding: 0.3em;
            }
            .helper-image {
            max-width: 90%; 
            height: auto; /* Wysokość auto zapewnia zachowanie proporcji obrazu */
            display: block; /* Sprawia, że obraz zajmuje całą dostępną szerokość linii */
            margin: 0 auto; /* Centruje obraz, jeśli jest mniejszy niż kontener */
          }          
        }
        
    } 
    .uhw-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1020;
        margin-top: 0.1em;
        font-weight: 700;
        font-size: 2em;
        width: 1.3em;
        height: 1.3em;
        border-radius: 0.5em;
        background-color: #252528;
        color: white;
        border: black solid 0.1em;
        margin-left: 1em;
        margin-right: 1em;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
    }
    .uhw-btn-exit{
        margin-top: 0.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1021;
        position: fixed;
        top: 0%;
        right: 3%;
        cursor: pointer;
        font-weight: 700;
        font-size: 2em;
        width: 1.3em;
        height: 1.3em;
        border-radius: 0.5em;
        background-color: #252528;
        color: white;
        border: black solid 0.1em;
        user-select: none;
        overflow: hidden;
        
        
    }
    .uhw-btn-invisible{
        margin-top: 0.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1020;
        // position: fixed;
        // top: 0%;
        // right: 3%;
        opacity: 0;
    
    
        font-weight: 700;
        font-size: 2em;
        width: 1.3em;
        height: 1.3em;
        border-radius: 0.5em;
        background-color: #252528;
        color: white;
        border: black solid 0.15em;
        user-select: none;
        overflow: hidden;
        
        
    }
}
