.DarkModeBtn{
    z-index: 1000;
    font-weight: 700;
    font-size: 2em;
    width: 1.3em;
    height: 1.3em;
    border-radius: 0.5em;
    background-color: #252528;
    color: white;
    border: black solid 0.1em;
    cursor: pointer;
    user-select: none;
    overflow: hidden;

    .slider{
       position: relative;
       height: 100%;
       

        .el{
            color: white;
            width: 100%;
            height: 100%;
            background-color: #252528;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;

            &:nth-of-type(2){
                position: relative;
                background-color: #252528;
                color: white;
            }
        }
        .el svg {
            width: 40px; // przykładowa wartość, dopasuj do swoich potrzeb
            height: 40px; // przykładowa wartość, dopasuj do swoich potrzeb
            padding: 5px;
          }
    }
    
}
