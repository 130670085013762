@keyframes bgcGreen {
  0% {
    background-color: rgba(0, 196, 25, 0.1);
  }
  50% {
    background-color: rgba(0, 196, 25, 0.5);
  }
  100% {
    background-color: rgba(0, 196, 25, 0.1);
  }
}

@keyframes bgcGreenLight {
  0% {
    background-color: rgba(50, 240, 100, 0.1);
  }
  50% {
    background-color: rgba(50, 240, 100, 0.5);
  }
  100% {
    background-color: rgba(50, 240, 100, 0.1);
  }
}

@keyframes bgcYellow {
  0% {
    background-color: rgba(252, 181, 0, 0.1);
  }
  50% {
    background-color: rgba(252, 181, 0, 0.5);
  }
  100% {
    background-color: rgba(252, 181, 0, 0.1);
  }
}

@keyframes bgcYellowLight {
  0% {
    background-color: rgba(252, 220, 100, 0.1);
  }
  50% {
    background-color: rgba(252, 220, 100, 0.5);
  }
  100% {
    background-color: rgba(252, 220, 100, 0.1);
  }
}

@keyframes bgcRed {
  0% {
    background-color: rgba(253, 10, 10, 0.1);
  }
  50% {
    background-color: rgba(253, 10, 10, 0.5);
  }
  100% {
    background-color: rgba(253, 10, 10, 0.1);
  }
}

@keyframes bgcRedLight {
  0% {
    background-color: rgba(253, 100, 100, 0.1);
  }
  50% {
    background-color: rgba(253, 100, 100, 0.5);
  }
  100% {
    background-color: rgba(253, 100, 100, 0.1);
  }
}

@keyframes bgcViolet {
  0% {
    background-color: rgba(128, 0, 255, 0.1);
  }
  50% {
    background-color: rgba(128, 0, 255, 0.4);
  }
  100% {
    background-color: rgba(128, 0, 255, 0.1);
  }
}

@keyframes bgcVioletLight {
  0% {
    background-color: rgba(166, 77, 255, 0.1);
  }
  50% {
    background-color: rgba(166, 77, 255, 0.4);
  }
  100% {
    background-color: rgba(166, 77, 255, 0.1);
  }
}

@keyframes bgcBlue {
  0% {
    background-color: rgba(0, 174, 239, 1);
  }
  50% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(0, 174, 239, 1);
  }
}

.app {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;

  .refreshButton{
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      color: white;
      background-color: #00aeff;
      border: none;
      font-weight: bold;
      padding: 10px;
      font-size: inherit;
      margin-top: 15px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

      @media (hover: hover) {
        &:hover {
          background-color: rgb(0, 137, 187);
          cursor: pointer;
        }
      }

      &:active {
        background-color: #646568;
      }
  }

  .updateAlertBox{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: fixed;
    bottom: 0%;
    // left: 0; /* Dodane */
    right: 1%; /* Dodane */
    // margin: auto; /* Dodane */
    // max-width: 23.4375em;
    transition: all 0.25s;
    background-color: #252528;
    color: white;
    // padding: 1em;
    border-radius: 0.25em;
    border: 2px solid #ffffff;
    // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 2px #0000008e;
    user-select: none;

    @media screen and (min-width: 270px) {
      font-size: 12px;
    }
    @media screen and (min-width: 370px) {
      font-size: 12px;
    }
    @media screen and (min-width: 450px) {
      font-size: 16px;
    }

    &:hover{
      cursor: pointer;
      box-shadow: 0px 0px 5px 2px #00aeff;
    }
    


    button{
      margin-left: 1em;
      padding: 0.5em;
      border-radius: 1em;
      border-radius: 0.25em;
      font-weight: bold;
      @media screen and (min-width: 270px) {
        font-size: 8px;
      }
      @media screen and (min-width: 370px) {
        font-size: 12px;
      }
      @media screen and (min-width: 450px) {
        font-size: 16px;
      }

      &:hover{
        background-color: rgba(240, 240, 240, 0.8);
        cursor: pointer;
      }
    }

    #info-icon-container{
      height: 50px;
      width: 50px;
      background-color: #00aeff;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-radius: 0.25em 0 0 0.25em;
    }

    #info-text-container{
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .UserHelperNavbarInvisibleHandler{
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

}

.vert-align-top-icon {
  vertical-align: top;
}

.vert-align-mid-icon {
  vertical-align: middle;
}

.vert-align-bottom-icon {
  vertical-align: bottom;
}

.no-events {
  pointer-events: none;
}
