@keyframes animatedBorder {
    0% {
      border-color: #00AEEF;
      box-shadow: 0 0 0 0 rgba(0, 174, 239, 0.7);
    }
    50% {
      border-color: #00AEEF;
      box-shadow: 0 0 10px 10px rgba(0, 174, 239, 0);
    }
    100% {
      border-color: #00AEEF;
      box-shadow: 0 0 0 0 rgba(0, 174, 239, 0);
    }
  }

  .animBorder {
    animation: animatedBorder 1.5s infinite;
    border: 3px solid;
    position: relative;
    z-index: 2001 !important;
  }

  .wizard-container {
    font-size: 11px;
    position: fixed; /* Pozycjonowanie względem viewportu */
    // top: 50%; /* Ustawienie na środku ekranu w pionie */
    left: 50%; /* Ustawienie na środku ekranu w poziomie */
    transform: translate(-50%, 0%); /* Centrowanie elementu */
    z-index: 10000; /* Upewnij się, że jest nad innymi elementami */


    transition: all 0.3s ease-in-out;
    
    width: auto; /* Możesz dostosować szerokość */
    max-width: 600px; /* Maksymalna szerokość */
    min-width: 260px;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white; /* Tło, aby tekst był czytelny na każdym tle */
    
    box-shadow: 0 4px 6px rgba(0,0,0,0.7); /* Lekkie cieniowanie dla lepszego efektu wizualnego */
    padding-top: 0.5em; /* Zwiększ padding, aby zrobić miejsce dla przycisku zamknięcia */
    

    @media (max-width: 600px) {
            font-size: 12px
    }
    
    @media (min-width: 601px) and (max-width: 1024px) {
            font-size: 14px; 
    }
    @media (min-width: 1025px) {
        font-size: 16px; 
}


    .wizard-step {
        // margin-bottom: 20px;
        font-weight: 500;
      }
      
      button {
        margin: 5px;
        padding: 0.6em 1.1em;
        border: none; /* Usuwa domyślną obwódkę */
        border-radius: 4px; /* Delikatne zaokrąglenie */
        background-color: #007BFF; /* Niebieskie tło */
        color: white; /* Biały tekst */
        cursor: pointer; /* Kursor wskazujący, aby pokazać, że jest klikalny */
        // font-size: 16px; /* Większa czcionka dla lepszej czytelności */
        @media (max-width: 600px) {
            font-size: 12px
        }
        
        @media (min-width: 601px) and (max-width: 1024px) {
                font-size: 14px; 
        }
        @media (min-width: 1025px) {
            font-size: 16px; 
        }
      }
      
      button:disabled {
        background-color: #ccc; /* Jasne tło dla zablokowanego przycisku */
        cursor: not-allowed; /* Kursor 'zakazany', aby pokazać, że przycisk jest nieaktywny */
      }
    
      .close-button {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        font-size: 24px; /* Duża czcionka, aby było łatwo kliknąć */
        width: 25px;
        height: 25px;
        color: #666; /* Ciemnoszary kolor, ale można dostosować */
      }
      
  }
  #bgblockwizard-container{
    position: fixed;
    z-index: 2000;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #00000090;
    display: none;
}
.bgblockwizardcontainer{
  position: fixed;
  z-index: 2002;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;

  background-color: #00000000;
}
