.options-menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #333;
    color: #fff;
    width: 80%;
    max-width: 600px;
    height: 60%;
    max-height: 400px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
  
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      border-radius: 0;
    }
  }
  
  .options-menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #444;
  }
  
  .options-menu__button {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  
    &:hover {
      color: #ccc;
    }
  }
  
  .options-menu__title {
    font-size: 18px;
  }
  
  .options-menu__content {
    font-weight: bold;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
    margin: 10px;
  }
  
  .options-menu__tile {
    background-color: #444;
    border-radius: 10px;
    margin: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    aspect-ratio: 1 / 1; /* Ustawienie proporcji kafelka na kwadratową */
    width: 90px; /* Ustawienie szerokości kafelka */
    height: 90px; /* Ustawienie wysokości kafelka */
  
    &:hover {
      background-color: #555;
    }
  }
  
  .options-menu__tile-icon {
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 10px;
    object-fit: contain; /* Zapewnia, że ikona będzie dopasowana do wyznaczonych wymiarów bez zniekształceń */
  }

  .options-menu__return-icon{
    width: 20px;
    height: 20px;
    color: white;
    fill: white;
  }

  .options-menu__tile-text {
    font-size: 16px;
    text-align: center;
  }
  
  .options-menu__subpage {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .options-menu__toggle-group {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .options-menu__toggle-button {
    display: flex;
    align-items: center;
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin: 0 10px;
    flex: 1;
  
    &:hover {
      background-color: #555;
    }
  
    &.active {
      background-color: #666;
      color: #fff;
    }
  }
  
  .options-menu__toggle-icon {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    margin-right: 10px;
    object-fit: contain; /* Zapewnia, że ikona będzie dopasowana do wyznaczonych wymiarów bez zniekształceń */
  }
  
  .options-menu__back-button {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #555;
    }
  }
  