:root{
  --headerfooter: #252528;
  --background: #FFFFFF;

  --themecolorcyan: #00FFFF;

  --themecolor1: #cbcbcd;
  --themecolor1shadow: #c3c3c3;

  --themecolor2: #f2f2f3;
  --themehover2: #e5e5e6;
  --themeactive2: #d8d8da;

  --themecolor3: #cacace;

  --themecolor4: #FFFFFF;

  --themecolor5: #cbcbcd;

  --themecolor6: #ffffff;

  --themecolor7: #b1b1b4;

  --themecolor8: #b4b4b4;

  --themecolor9: #797979;

  --themecolor10: #444444;

  --themecolor11: #cacace;
  --themecolor12: #7d7d7d;

  --buttoncolor1: #939498;
  --buttonhover1: #7d7e82;
  --buttonactive1: #646568;

  --buttoncolor2: #939498;
  --buttonhover2: #8a8b8f;
  --buttonactive2: #7d7e82;

  --buttoncolor3: #939498;

  --buttoncolor4: #525252;
  --buttonhover4: #4e4e4e;
  --buttonactive4: #3d3d3d;

  --textcolor1: #252528;
  --textcolor2: #000000;

  [data-theme="dark"]{
    --headerfooter: #252528;
    --background: #323233;

    --themecolorcyan: #4fd0ff;
    /* #a6e7ff; #4fd0ff; */
    --themecolor1: #515151;
    --themecolor1shadow: #494949;

    --themecolor2: #616161;
    --themehover2: #494949;
    --themeactive2: #434343;

    --themecolor3: #515151;

    --themecolor4: #757575;

    --themecolor5: #cbcbcd;

    --themecolor6: #f3f3f3;

    --themecolor7: #424242;

    --themecolor8: #757575;

    --themecolor9: #747474;

    --themecolor10: #424242;

    --themecolor11: #616161;
    --themecolor12: #616161;

    --buttoncolor1: #424242;
    --buttonhover1: #3b3b3b;
    --buttonactive1: #323232;

    --buttoncolor2: #424242;
    --buttonhover2: #3b3b3b;
    --buttonactive2: #323232;

    --buttoncolor3: #424242;

    --buttoncolor4: #424242;
    --buttonhover4: #3b3b3b;
    --buttonactive4: #323232;


    --textcolor1: #FFFFFF;
    --textcolor2: #FFFFFF;
  }
    /* transition: all 0.3s ease-in-out; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  transition: all 0.3s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}