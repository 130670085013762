.copyright {
  position: static;
  padding: 10px 10px 0px 10px;
  color: white;
  font-size: 12px;
  height: 40px;
  width: auto;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  background-color: var(--headerfooter);
  margin: auto;

  &.copyright-login {
    @media screen and (max-width: 699px) {
      color: white;
    }

    @media screen and (min-height: 450px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.absolute {
    position: absolute;
  }
}
