.iconsMenu{
    z-index: 1000;
    display: flex;
    position: fixed;
    bottom: 0.5%;
    right: 0%;
    background-color: #252528;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 10px;
    border-radius: 100px 0px 0px 100px;
    border: black solid 0.15em;
    // box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    .center-element {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
}